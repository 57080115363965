export const API = {
  ACTIVITY: {
    DETAIL: ({ class_id, topic_id, act_id }) =>
      `users/v1/myprogram/detail_activity/${class_id}/${topic_id}/${act_id}`,
    QUESTION: ({ class_id, topic_id, act_id }) =>
      `/users/v1/myprogram/question_list/${class_id}/${topic_id}/${act_id}`,
    UPDATE_IS_COMPLETE: `users/v1/member/activity/update_is_complete`,
    SUBMIT_ANSWER: `/users/v1/myprogram/answer`,
    ANSWER: ({ class_id, topic_id, act_id }) =>
      `users/v1/myprogram/answer_list/${class_id}/${topic_id}/${act_id}`,
    DONE: `users/v1/member/activity/update-has-done`,
  },
  QUESTION: {
    MULTIPLE: `bank/v1/question/public/detail_multiple`,
  },
  MYPROGRAM_CHECK_REPEAT_QUESTION: "users/v1/myprogram/check-repeat-question",
};

export default { API };
