<template>
  <!-- chat-input -->
  <v-container class="d-flex justify-space-between align-center" style="gap: 5px;">
    <v-textarea outlined hide-details autofocus v-model.trim="message"
      placeholder="Ketik pesan disini, tekan enter untuk mengirim" v-on:keyup.enter.exact="submitHandler" rows="1"
      auto-grow>
    </v-textarea>
    <v-btn @click="submitHandler" color="primary" fab depressed width="50" height="50">
      <v-icon size="28">ri-send-plane-2-fill</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: "ChatInput",
  props: {
    act: Object,
    topic: Object,
    detailClass: Object
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    ...mapActions('roomModule', ['sendMessage']),
    async submitHandler() {
      if (this.message.length > 0) {
        // this.message = `<div style="font-size: 13px;">${this.message}</div>`;

        const { message } = this;

        this.sendMessage({ message, topic: { id: this.topic.id, name: this.topic.name }, activity: { id: this.act.id, name: this.act.title }, license: this.detailClass.license })
          .then(() => {
            this.message = '';
          })
          .catch((err) => {

            if (err.response.data.message == 'error validation') {
              let errors = "";
              Object.keys(err.response.data.validation).forEach((error) => {
                errors += err.response.data.validation[error] + "<br>";
              })
              this.$emit('error', errors)
            } else {
              this.$emit('error', err.response.data.message)
            }
          })

      }
      this.resetMessage();
    },
    resetMessage() {
      this.message = '';
    }
  }
}
</script>