import axios from "axios";
import { API } from ".";

export const postCheckRepeatQuestion = (payload) => {
  return axios
    .post(API.MYPROGRAM_CHECK_REPEAT_QUESTION, payload)
    .catch((err) => {
      console.error("[err]: postCheckRepeatQuestion", err);
      throw err;
    });
};
