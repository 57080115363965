<template>
  <v-row align-content="center">
    <v-col class="py-0 mx-auto" md="12">
      <v-row>
        <v-col v-show="send" class="mx-auto white" md="6" style="height: 100vh">
          <v-spacer class="py-3"></v-spacer>
          <router-link class="px-8 font-weight-medium text-decoration-none" tabindex="-1" color="primary"
            :to="{ name: 'login' }">
            <span><v-icon color="primary"> mdi-arrow-left</v-icon> Kembali Login</span>
          </router-link>
          <v-spacer class="py-8"></v-spacer>
          <v-form class="px-8" @submit.prevent="submit" lazy-validation ref="formku" v-model="valid">
            <div class="mb-4">
              <span class="fs-20 font-weight-medium">Masukan email anda dan kami akan mengirimkan kode
                verifikasi untuk memperbarui password anda</span>
            </div>
            <v-alert type="error" text dense prominent v-show="response !== null">{{ response }}</v-alert>
            <v-alert type="success" text dense prominent v-show="responsesukses !== null">{{ responsesukses }}</v-alert>
            <v-row>
              <v-col class="pb-0">
                <div class="mb-2 d-md-flex justify-space-between">
                  <div class="font-weight-medium">Email</div>
                  <!-- <div class="mt-auto color-disable text-caption" tabindex="-1">
                    *pastikan anda memiliki akun WA
                  </div> -->
                </div>
                <v-text-field class="rounded-xl" v-model="email" placeholder="Masukkan email disini" :rules="emailRules"
                  required outlined hide-details="auto"></v-text-field>
                <!-- <span class="fs-12 text-danger">*Harap mengganti awalan nomor dengan angka 62. Cth:62897654321</span> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-2">
                <v-btn large elevation="0" color="primary" :loading="submiting" block class="rounded-xl" @click="otp()">
                  Lanjut
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col v-show="verify" class="py-0 mx-auto" md="12">
          <v-row>
            <v-col class="mx-auto white" md="6" style="height: 100vh">
              <v-spacer class="py-16"></v-spacer>
              <div class="px-8">
                <v-row class="mb-0" justify="center">
                  <v-col sm="6" lg="12" class="text-center">
                    <v-icon class="text-h2 color-strike">mdi-message-processing-outline</v-icon>
                    <div class="text-h5 color-strike">Masukan Kode Verifikasi</div>
                    <p class="text-subtitle-1 color-disable">Cek inbox/spam email anda kemudian masukan kode verifikasi
                      yang telah kami kirimkan
                    </p>
                  </v-col>
                </v-row>
                <v-form lazy-validation ref="form" v-model="valid" class="mb-2">
                  <v-container>
                    <v-alert type="error" text dense prominent v-show="response !== null">{{ response }}</v-alert>
                    <v-row>
                      <v-col id="kode" cols="12" sm="6" class="py-0 mx-auto">
                        <!-- <v-text-field
                          v-model="kodeOtp"
                          required
                          :rules="kodeRules"
                        ></v-text-field> -->
                        <v-otp-input :disabled="loading" @finish="reset()" length="6" v-model="kodeOtp"></v-otp-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <div class="mb-3 text-center text-subtitle-2 color-disable">
                  <span v-show="timerMessage">Mohon tunggu dalam <b>{{ totalSeconds | minutesAndSeconds }} detik </b>
                    untuk kirim ulang</span>
                  <span v-show="resend">
                    Tidak menerima kode ?
                    <v-btn plain color="primary" @click="sendOtp()" class="pa-0 font-weight-bold">Kirim ulang
                    </v-btn>
                  </span>
                </div>
                <!-- <v-btn
                  rounded
                  block                  
                  color="primary"
                  class=""
                  @click="reset()"
                >Verifikasi
                </v-btn> -->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-snackbar top v-model="snackbar.state" color="primary" outlined>
        <!-- :timeout="timeout" -->
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "password-send-email",
  data() {
    return {
      errors: [],
      loading: false,
      valid: true,
      email: "",
      emailRules: [
        (v) => !!v || "Email tidak boleh kosong",
        (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
      ],
      submiting: false,
      response: null,
      responsesukses: null,
      send: true,
      verify: false,
      kodeOtp: "",
      totalSeconds: 60,
      timer: {},
      interval: {},
      timerMessage: true,
      resend: false,
      idMember: '',
      kodeRules: [
        (v) => !!v || "Kode OTP tidak boleh kosong",
        (v) => (v && v.length === 6) || "Kode OTP harus 6 karakter",
      ],

      snackbar: {
        state: false,
        text: "",
      },
    };
  },
  filters: {
    minutesAndSeconds(value) {
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${seconds}`;
    },
  },
  methods: {
    submit() {
      // this.$router.push({ name: 'success-send-email'})
    },
    otp() {
      this.submiting = true;
      if (this.$refs.formku.validate()) {
        let data = { email: this.email, access_key: process.env.VUE_APP_ACCESS_KEY };

        this.axios
          // .put(`/api/v1/reset/verify/phone`,data)
          .post(`/auth/v1/member/forgot-password`, data)
          .then(() => {
            this.submiting = false;
            // this.idMember=res.data.data.id;
            // console.log(this.idMember)
            this.verify = true;
            this.send = false;
            this.timer_start();
            this.response = null;
          })
          .catch((error) => {
            this.submiting = false;
            this.dialog = false;
            this.response = error.response.data.message;
            setTimeout(() => { this.response = null; }, 4000);
            // console.error(error);
          });
      } else {
        this.submiting = false;
      }
    },
    timer_start() {
      this.timer_stop();
      this.timer = setInterval(() => {
        if (this.totalSeconds === 0) {
          this.totalSeconds = 60;
          clearInterval(this.timer);
          this.timerMessage = false;
          this.resend = true;
        }
        this.totalSeconds -= 1;
      }, 1000);
    },
    timer_stop() {
      clearInterval(this.timer);
    },
    sendOtp() {
      this.timerMessage = true;
      this.resend = false;
      this.otp();
    },
    reset() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let data = {
          access_key: process.env.VUE_APP_ACCESS_KEY,
          email: this.email,
          otp: this.kodeOtp,
          type: 'forgot_password'
        };

        this.axios
          .post(`/auth/v1/member/verify-otp`, data)
          // .post(`/api/v1/reset/verify/otp/${this.idMember}`,data)
          .then((res) => {
            this.submiting = false;
            this.$router.push({ name: 'password-reset', params: { id: res.data.data.token } })
            // console.log(res.data)                        
          })
          .catch((error) => {
            this.submiting = false;
            this.verify = true;
            this.regis = false;
            this.kodeOtp = '';
            if (error.response) {
              this.snackbar.text = error.response.data.message;
              this.snackbar.state = true;
            }
          });
      } else {
        this.submiting = false;
      }

    }
  },
};
</script>

<style></style>