<template>
  <v-row :class="$vuetify.breakpoint.name == 'xs' ? 'mx-2' : 'ml-1'">
    <div class="font-weight-bold mb-3 text-capitalize">{{ act.title }}</div>
    <v-card elevation="0" style="height: 100vh; width: 100%">
      <v-card-subtitle>
        <div v-html="act.theory.description"></div>
      </v-card-subtitle>
      <v-divider></v-divider>
      <!-- <vue-pdf-app :pdf="act.theory.file.url +'#toolbar=0'" :config="config" style="z-index: 0 !important;"></vue-pdf-app> -->
      <div v-if="isLoading">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
      <vue-pdf-embed annotationLayer textLayer :source="act.theory.file.url"
        style="height: 90vh; width: 100%; overflow-y: scroll" @rendered="handleDocumentRender" />
    </v-card>
  </v-row>
</template>

<script>
// import VuePdfApp from "vue-pdf-app";
// import "vue-pdf-app/dist/icons/main.css";
// import VuePdfEmbed from 'vue-pdf-embed'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    // VuePdfApp,
    VuePdfEmbed
  },
  props: {
    act: Object
  },
  data() {
    return {
      isLoading: true,
      config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      },
    }
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
    }
  }
}
</script>

<style>
.pdf-app .toolbar {
  z-index: 0 !important;
}
</style>