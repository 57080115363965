<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <div class="">
        <v-main class="grey">
          <div class="d-flex flex-column" style="min-height: 100vh;">
            <v-app-bar id="menu-bar" class="white" v-show="show_sidebar" flat fixed>
              <v-toolbar-title>
                <div class="my-1 text-left">
                  <img :src="header_logo" alt="Logo" class="img-yec-test" @click="beranda()" style="cursor: pointer;" />
                </div>
              </v-toolbar-title>
              <v-btn @click="beranda()" class="me-2 transparent primary--text d-none d-md-block" elevation="0">
                Beranda
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="false" @click="prakerja()" class="me-2 transparent primary--text d-none d-md-block"
                elevation="0">
                Kelas Prakerja
              </v-btn>
              <v-btn @click="signIn()" outlined class="me-2 primary--text d-none d-md-block" elevation="0">
                Masuk
              </v-btn>
              <v-btn @click="signUp()" color="primary" class="d-none d-md-block" elevation="0">
                Daftar
              </v-btn>
              <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="dialog.menu = true">
              </v-app-bar-nav-icon>
              <v-dialog v-model="dialog.menu" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                  <v-toolbar>
                    <v-toolbar-title>
                      <div class="my-1 text-left">
                        <img :src="header_logo" alt="Logo" class="img-yec-test" />
                      </div>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn icon dark @click="dialog.menu = false" color="primary">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-spacer></v-spacer>
                  <v-card-text class="pa-5">
                    <v-btn @click="signUp(); dialog.menu = false" color="primary" class="mb-4 rounded-xl" block
                      elevation="0">
                      Daftar
                    </v-btn>
                    <v-btn @click="signIn(); dialog.menu = false" class=" transparent primary--text" block elevation="0">
                      Masuk
                    </v-btn>
                    <v-btn v-if="false" @click="prakerja(); dialog.menu = false" class=" transparent primary--text" block
                      elevation="0">
                      Kelas Prakerja
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-app-bar>
  
            <v-container fluid
              :style="$route.name != 'login' && $route.name !== 'registration' ? 'margin-top: 64px; padding: 0px;' : 'height: 100vh;'">
              <transition name="fade">
                <router-view v-if="loaded"></router-view>
              </transition>
            </v-container>
            <div id="footer" class="py-3 white mt-auto" v-show="show_sidebar">
              <div class="container">
                <div class="row">
                  <div class="text-justify col-md-4">
                    <img :src="footer_logo" alt="Logo" class="mb-2 img-yec-test" />
                    <div class="pe-5">
                      <p class="fs-16 fs-xs-14" style="white-space: break-spaces">{{ site_data.short_description }}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-4 fs-24 fs-xs-20 font-weight-bold">Alamat Kantor</p>
  
                    <div v-for="(address, i) in site_data.addresses" :key="i">
                      <p class="mb-2 fs-18 fs-xs-16 font-weight-regular">{{ address.title }}</p>
                      <div class="mb-4 pe-5">
                        <p class="mb-0 fs-16 fs-xs-14" style="white-space: break-spaces">{{ address.address }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-2 fs-24 fs-xs-16 font-weight-bold">Temukan Kami</p>
  
                    <div v-for="(item, i) in Object.keys(companyContacts)" :key="i">
                      <div v-if="companyContacts[item].label" class="mb-2">
                        <a class="d-flex align-center" :href="companyContacts[item].link" target="_blank">
                          <v-icon v-if="item != 'tiktok'" size="30" color="black" class="mr-2">{{ renderIcon(item)
                            }}</v-icon>
                          <img v-else :src="require('@/assets/images/icon_tiktok.svg')" alt="icon tiktok" width="24"
                            class="mr-4">
                          <div>{{ companyContacts[item].label }}</div>
                        </a>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div id="footer" class="py-3 white" v-show="show_sidebar">
            <div class="container">
              <div class="row">
                <div class="col-md-3">
                  <img :src="header_logo" alt="Logo" class="mb-2 img-yec-test" />
                  <p class="mb-2 fs-16 fs-xs-14">
                    <v-icon>mdi-email-outline</v-icon>
                    email@email.com
                  </p>
                  <p class="fs-16 fs-xs-14">
                    <v-icon>mdi-whatsapp</v-icon>
                    081234567890
                  </p>
                  <div class="pe-5">
                    <p class="fs-16 fs-xs-14">
                      TEFLIN LMS
                    </p>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Tentang Kami</p>
                  <div>
                    <router-link to="/">Tentang TEFLIN LMS</router-link>
                  </div>
                  <div class="pt-2">
                    <router-link to="/">Kontak Kami</router-link>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Bantuan dan Panduan</p>
                  <div>
                    <router-link to="/privacy">Kebijakan Privasi</router-link>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Lainnya</p>
                  <div>
                    <router-link to="/">Blog</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </v-main>
      </div>
    </v-app>
  </transition>
</template>
<script>
import {
  mapGetters,
  mapState
}
  from "vuex";

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: false,
      group: null,
      notifications: [1],
      pageTitle: "",
      // title:false,
      pathnya: "",
      dialog: {
        menu: false,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState({
      site_data: state => state.company.site_data
    }),
    header_logo() {
      return this.site_data.logo?.url;
    },
    footer_logo() {
      return this.site_data.footer_logo?.url;
    },
    companyContacts() {
      // make sort data contact to be this order : website, instagram, youtube, email, tiktok, facebook, telegram, whatsapp
      let contact = this.site_data.contact;
      let sortedContact = {};
      let order = ['website', 'instagram', 'youtube', 'email', 'tiktok', 'facebook', 'telegram', 'whatsapp'];
      order.forEach((key) => {
        if (contact[key]) {
          sortedContact[key] = contact[key];
        }
      });
      return sortedContact;
    },

    show_sidebar() {
      return !/\/auth\/.\.*/.test(this.$route.path);
    },
  },
  created() {
    // this.disableRight();
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
      // console.log(pageTitle);
    });

    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;
    // console.log("pathnya", this.pathnya);
    // let index = this._.findIndex(this.items, { link: path });
    // if (index != -1) {
    //   this.pageTitle = this.items[index].title;
    //   this.breadcrumbs.push({
    //     text: this.items[index].title,
    //     disabled: true,
    //     href: path,
    //   });
    // }
  },
  methods: {
    renderIcon(value) {
      switch (value) {
        case "website":
          return "mdi-web";
        case "instagram":
          return "mdi-instagram";
        case "youtube":
          return "mdi-youtube";
        case "email":
          return "mdi-email";
        case "tiktok":
          return "mdi-tiktok";
        case "facebook":
          return "mdi-facebook";
        case "telegram":
          return "mdi-telegram";
        case "whatsapp":
          return "mdi-whatsapp";
        default:
          return "mdi-web";
      }
    },
    menuItems() {
      return this.menu;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
        window.location.reload(true);
      });
    },
    beranda() {
      this.$router.push("/");
    },
    signUp() {
      this.$router.push("/auth/registration");
    },
    signIn() {
      this.$router.push("/auth/login");
    },
    prakerja() {
      this.$router.push("/kelas-prakerja");
    },
    // menu() {
    //   this.$router.push("/");
    // },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " + this.site_data.name
      // 'TEFLIN LMS';
    },
  },
};
</script>
