<template>
  <v-card elevation="0" class="px-2 transparent" @click="showCertif">
    <vue-html2pdf ref="certifPeserta" :enable-download="true" :preview-modal="true" :show-layout="false"
      :pdf-quality="2" :filename="certificateName" pdf-orientation="landscape" :manual-pagination="true"
      pdf-content-width="100%" pdf-format="legal" @hasDownloaded="hasGenerated($event)">
      <section slot="pdf-content">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bg-certificate.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div style="
                font-style: normal;
                text-align: center;
                font-family: Inter, sans-serif;
                font-weight: 900;
                font-size: 40px;
                line-height: auto;
                margin-top: 15%;
                /* margin-left: 8%; */
                color: black;
              ">
              {{ data.user_name }}
            </div>

            <div style="
                font-style: normal;
                text-align: center;
                font-family: Inter, sans-serif;
                font-weight: 700;
                font-size: 26px;
                line-height: 20px;
                margin-top: 7%;
                /* margin-left: 8%; */
                color: black;
              ">
              {{ data.program_name }}
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
import moment from "moment";
import "moment/locale/id";
import VueHtml2pdf from "vue-html2pdf";
// import VueQrcode from '@chenfengyuan/vue-qrcode';
// import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  components: {
    VueHtml2pdf,
    // VueQrcode 
  },
  data() {
    return {
      certificateName: '',
      dialog: true,
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      is_prakerja: null,
      data: {},
      test: '10',
      member: {},
      program: '',
      competence: [],
      result_score: {},
      dialogCertif: false,
      styleObject: {
        paddingTop: '100px',
        paddingBottom: '180px'
      },
      styleObject2: {
        paddingTop: '100px',
        paddingBottom: '100px'
      },
      styleObject3: {
        paddingTop: '100px',
        paddingBottom: '80px'
      },
      timer: '',
      score: '',
      dateFinish: [],
      tgl: null
    };
  },
  computed: {},
  created() {
    this.getData();
    // this.timer = setInterval(this.getData, 2000)
  },
  mounted() {
  },
  methods: {
    async getData() {
      this.false = true;
      await this.axios
        // .get(`users/v1/member_redeem/detail/${this.$route.params.id}`,{headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }})
        .get(`users/v1/public/order/detail/${this.$route.params.id}`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
        })
        .then((response) => {
          let res = response.data.data;
          this.data = res;
          this.certificateName = res.user_name + " - " + res.program_name;
          setTimeout(() => {
            this.showCertif();
          }, 200);
        });
    },
    cancelAutoUpdate() { clearInterval(this.timer) },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    certificatePeriode(created_at) {
      return moment(created_at).locale("id").format("MMMM YYYY") + " - " + moment(created_at).locale("id").add(10, "M").format("MMMM YYYY");
    },
    converDatetime(created_at) {
      return moment(created_at).locale("id").format("DD MMMM YYYY");
    },
    currentDateTime(a) {
      return moment(a).locale("nl").format("L");
    },
    currentDateTimes(a) {
      return moment(a).locale("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    convertHour(val) {
      let minutes = 0
      let hours = 0
      let last = ''
      if (val > 60) {
        hours = val / 60
        minutes = val % 60
        if (minutes > 0) {
          last = parseInt(hours) + ' Jam ' + minutes + ' Menit'
        } else {
          last = hours + ' Jam '
        }
      } else {
        minutes = val
        last = minutes + ' Menit'
      }
      return last
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            console.log("berhasil", res);
          }
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    getDetail(order) {
      this.id = order;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.id}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail = res.program;
          console.log("data detail", this.detail);
          this.showCertif(this.id);
        });
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

tr:nth-child(even) {
  background-color: #f9fafc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr {
  color: #3c4858;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}

.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}

.style1 {
  padding-top: 100px;
  padding-bottom: 188px
}

.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}

.style3 {
  padding-top: 100px;
  padding-bottom: 148px;
}

.style4 {
  padding-top: 100px;
  padding-bottom: 116px;
}

.style5 {
  padding-top: 100px;
  padding-bottom: 0px;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>