<template>
  <div>
    <hr style="border-color: transparent" />
    <section class="mt-5 mb-5 white">
      <div class="mx-auto col-md-10">
        <h3>
          <strong><span style="color: #3c4858; font-size: 13pt">KEAMANAN DAN PRIVASI</span></strong>
        </h3>
        <p style="text-align: justify">
          <span style="color: #3c4858; font-size: 12pt">
            Dengan mengakui pentingnya informasi pribadi yang disediakan oleh
            pengguna, kami, PT. Kayana Digdaya Sejahtera, mengelola informasi
            ini sebagaimana dijelaskan di bawah ini untuk menanganinya dengan
            benar. PT. Kayana Digdaya Sejahtera (&ldquo;Kami atau TEFLIN) terus
            menjaga keamanan dari seluruh data dan informasi penting pelanggan.
            Server yang terjamin, perlindungan data privasi, back-up berkala,
            serta akses ke data pelanggan, merupakan upaya kami untuk terus
            menjaga keamanan dan privasi setiap pelanggan. Kami berkomitmen
            untuk melindungi dan menghormati privasi pengguna
            (&ldquo;Anda&rdquo;) saat mengakses dan menggunakan situs
            <a href="https://situs.co.id">https://situs.co.id</a>
            dan situs web lain yang kami kelola, fitur, teknologi, konten dan
            produk yang kami sediakan (selanjutnya, secara bersama-sama disebut
            sebagai &ldquo;Platform&rdquo;).
          </span>
        </p>
        <h4>
          <strong>
            <span style="color: #3c4858; font-size: 11pt">
              KEBIJAKAN PRIVASI
            </span>
          </strong>
        </h4>
        <p style="text-align: justify">
          <span style="color: #3c4858; font-size: 12pt">
            Kebijakan Privasi berikut ini menjelaskan bagaimana Kami (PT. Kayana
            Digdaya Sejahtera dan afiliasi-afiliasi, atau &ldquo;Kami&rdquo;)
            mengumpulkan, menyimpan, menggunakan, mengolah, menguasai,
            mentransfer, mengungkapkan dan melindungi Informasi Pribadi.
            Kebijakan Privasi ini berlaku bagi seluruh pengguna platform kami,
            kecuali diatur pada kebijakan privasi yang terpisah.
          </span>
        </p>
        <p style="text-align: justify">
          <span style="color: #3c4858; font-size: 12pt">
            Mohon baca Kebijakan Privasi ini dengan seksama untuk memastikan
            bahwa Anda memahami bagaimana proses pengolahan data kami. Kecuali
            didefinisikan lain, semua istilah dengan huruf kapital yang
            digunakan dalam Kebijakan Privasi ini memiliki arti yang sama dengan
            yang tercantum dalam Ketentuan Penggunaan.
          </span>
        </p>
        <p style="text-align: justify">
          <span style="color: #3c4858; font-size: 12pt">
            TEFLIN memiliki kebijakan privasi yang jelas dalam mendefinisikan
            apa saja data yang dikumpulkan dan bagaimana data tersebut
            digunakan. TEFLIN berkomitmen untuk menjaga privasi pelanggan serta
            memberikan transparansi atas bagaimana TEFLIN bekerja.
          </span>
        </p>
        <p>
          <span style="color: #3c4858; font-size: 12pt">
            Kebijakan Privasi ini mencakup hal-hal sebagai berikut:
          </span>
        </p>
        <ol>
          <li v-for="(text) in Kebijakan_Privasi_ini_mencakup_hal_hal_sebagai_berikut" :key="text"
            style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p>
              <span style="color: #3c4858; font-size: 12pt">
                {{ text }}
              </span>
            </p>
          </li>
        </ol>
        <p style="text-align: justify">
          <span style="color: #3c4858; font-size: 12pt">
            Dengan mengunjungi dan/atau mendaftar akun pada layanan kami, Anda
            menerima dan menyetujui pendekatan dan cara-cara yang digambarkan
            dalam Kebijakan Privasi ini
          </span>
        </p>
        <ol>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  INFORMASI PRIBADI YANG DAPAT KAMI KUMPULKAN
                </span>
              </strong>
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  <br />
                </span>
              </strong>
              <span style="color: #3c4858; font-size: 12pt">
                Kami mengumpulkan informasi yang mengidentifikasikan atau dapat
                digunakan untuk mengidentifikasi, menghubungi, atau menemukan
                orang atau perangkat yang terkait dengan informasi tersebut
                (&ldquo;Informasi Pribadi&rdquo;).
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Kami dapat mengumpulkan Informasi Pribadi selanjutnya disebut
                &ldquo;Informasi&rdquo; dalam berbagai macam bentuk dan tujuan (termasuk
                tujuan yang diizinkan berdasarkan peraturan perundang-undangan
                yang berlaku) untuk memberikan pelayanan sebagai berikut:
              </span>
            </p>
            <ol>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Informasi yang Anda berikan
                  </span>
                  <span style="color: #3c4858; font-size: 12pt"><br /></span>
                  <span style="color: #3c4858; font-size: 12pt">
                    Kami dapat mengumpulkan berbagai format data dari atau tentang Anda, bergantung pada cara Anda
                    menggunakan layanan. Anda dapat memberikan informasi melalui formulir elektronik pada layanan kami
                    maupun dengan berkorespondensi melalui telepon, surat elektronik, dan sebagainya. Informasi ini
                    meliputi informasi yang Anda berikan ketika mendaftar pada layanan Kami, berlangganan layanan kami,
                    mencari produk, berpartisipasi dalam diskusi online atau fungsi media sosial lain pada layanan kami,
                    mengikuti kompetisi, promosi, atau survei, serta ketika Anda melaporkan masalah dengan layanan kami.
                    Informasi yang Anda berikan dapat meliputi nama, alamat, alamat surat elektronik, nomor telepon,
                    informasi finansial dan kartu kredit, deskripsi pribadi, foto, dan data lainnya. Kami dapat meminta
                    Anda untuk melakukan verifikasi terhadap informasi yang Anda berikan, untuk memastikan akurasi dari
                    informasi tersebut.
                  </span>
                  <span style="color: #3c4858; font-size: 12pt"><br /></span>
                  <span style="color: #3c4858; font-size: 12pt">
                    Saat Anda membuat akun dan menggunakan layanan, termasuk melalui platform pihak ketiga, Kami
                    mengumpulkan setiap data yang Anda berikan secara langsung, termasuk:
                  </span>
                </p>
                <ol>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Data akun agar dapat mengakses pelatihan, Anda perlu membuat akun pengguna. Saat Anda membuat
                        atau memperbarui akun, Kami mengumpulkan dan menyimpan data yang Anda berikan, seperti nama
                        lengkap, alamat email, kata sandi, nomor telepon dan tanggal lahir, atau serta memberi Anda
                        nomor pengenal unik (&ldquo;Data Akun&rdquo;).
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Data profil Anda juga dapat memilih untuk menyediakan informasi profil seperti nama dan email.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Data kursus
                      </span>
                      <span style="color: #3c4858; font-size: 12pt"><br /></span>
                      <span style="color: #3c4858; font-size: 12pt">
                        Ketika Anda mendaftar dan mengikuti kursus, Kami mengumpulkan data tertentu termasuk kursus,
                        tugas, dan kuis yang Anda sudah mulai dan selesaikan.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Data tentang Akun Anda di layanan lainnya
                      </span>
                      <span style="color: #3c4858; font-size: 12pt"><br /></span>
                      <span style="color: #3c4858; font-size: 12pt">
                        Kami dapat memperoleh informasi tertentu melalui akun media sosial atau online Anda lainnya jika
                        akun tersebut terhubung ke akun situs.co.id. Jika Anda login ke situs.co.id melalui platform
                        atau layanan pihak ketiga lainnya. Kami meminta izin Anda untuk mengakses informasi tertentu
                        tentang akun lainnya tersebut. Misalnya, bergantung pada platform atau layanan, Kami dapat
                        mengumpulkan nama, profil, foto, nomor ID akun, alamat email login, lokasi, lokasi fisik, atau
                        akses perangkat Anda, jenis kelamin, tanggal lahir, dan daftar teman atau kontak.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p>
                      <span style="color: #3c4858; font-size: 12pt">
                        Data akun yang Anda gunakan akan kami simpan di dalam sistem Kami dengan API secara aman dan
                        terjaga kerahasiaannya.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p>
                  <span style="color: #3c4858; font-size: 12pt">
                    Informasi yang kami kumpulkan. Untuk setiap kunjungan Anda
                    ke layanan kami. Kami dapat mengumpulkan informasi berikut
                    secara otomatis:
                  </span>
                </p>
                <ol>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Informasi teknis, meliputi alamat Protokol Internet (IP address) yang digunakan untuk
                        menghubungkan komputer Anda dengan internet, informasi log in Anda, jenis dan versi perambah
                        (browser) yang digunakan, pengaturan zona waktu, jenis dan versi ekstensi perambah (browser
                        plug-in), system operasi dan Layanan;
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Informasi tentang kunjungan Anda, termasuk daftar lengkap Lokator Sumber Seragam (Uniform
                        Resource Locators atau URL) yang dikunjungi menuju, melalui, dan dari layanan Kami (termasuk
                        tanggal dan waktu); produk yang Anda lihat atau cari; waktu respon halaman, masalah pengunduhan,
                        lama kunjungan pada halaman tertentu, informasi interaksi pada halaman (seperti pengguliran,
                        klik, maupun pergerakan tetikus), metode yang digunakan untuk meninggalkan situs, serta nomor
                        telepon yang digunakan untuk menghubungi layanan pelanggan Kami.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Ketika Anda menggunakan layanan/aplikasi atau mengunjungi situs web Kami, informasi tertentu
                        juga dapat dikumpulkan secara otomatis dengan menggunakan cookies. Cookies adalah berkas data
                        kecil yang tersimpan pada komputer atau perangkat seluler Anda. Kami menggunakan cookies untuk
                        melacak aktivitas pengguna dengan tujuan untuk meningkatkan antarmuka dan pengalaman pengguna.
                        Sebagian besar perangkat seluler dan peramban internet mendukung penggunaan cookies; namun Anda
                        dapat menyesuaikan pengaturan pada perangkat seluler atau peramban internet Anda untuk menolak
                        beberapa jenis cookies atau cookies spesifik tertentu. Perangkat seluler dan/atau peramban Anda
                        juga memungkinkan Anda untuk menghapus cookies apa pun yang sebelumnya telah tersimpan. Namun
                        demikian, hal itu dapat mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi atau situs web
                        Kami.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Data nilai Anda, termasuk namun tidak terbatas pada hasil ujian Anda yang diperoleh melalui
                        layanan, serta data akademis lain.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Informasi yang kami terima dari sumber lain. Kami dapat menerima informasi jika Anda menggunakan
                    situs lain yang Kami operasikan atau layanan lain yang Kami sediakan. Kami juga bekerja sama dengan
                    pihak ketiga (termasuk, namun tidak terbatas pada misalnya, mitra bisnis, sub-kontraktor dalam
                    pelayanan teknis, jasa pembayaran dan pengantaran, jaringan periklanan, penyedia analisis, penyedia
                    pencarian informasi, serta agen acuan kredit) (&ldquo;Mitra Kami&rdquo;) dan dapat menerima
                    informasi dari mereka. Kami akan mengambil langkah-langkah dalam batas kewajaran untuk melakukan
                    verifikasi terhadap informasi yang Kami dapatkan dari sumber lain sesuai dengan Peraturan yang
                    Berlaku.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol start="2">
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  PENGGUNAAN INFORMASI PRIBADI
                </span>
              </strong>
              <strong>
                <span style="color: #3c4858; font-size: 12pt"><br /></span>
              </strong>
              <span style="color: #3c4858; font-size: 12pt">
                Kami dapat menggunakan Informasi Pribadi untuk tujuan sebagai berikut maupun untuk tujuan lain yang
                diizinkan oleh peraturan perundang-undangan yang berlaku (&quot;Tujuan&quot;):
              </span>
            </p>
            <ol>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Informasi yang Anda berikan
                  </span>
                  <span style="color: #3c4858; font-size: 12pt"><br /></span>
                  <span style="color: #3c4858; font-size: 12pt">
                    Kami akan menggunakan informasi ini:
                  </span>
                </p>
                <ol>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        untuk menjalankan kewajiban kami dalam menyediakan
                        informasi, produk, dan jasa kepada Anda;
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk menyediakan informasi terkait produk dan jasa
                        lain yang Kami tawarkan guna menyediakan Anda, atau
                        mengizinkan pihak ketiga untuk menyediakan Anda,
                        informasi tentang produk dan jasa yang kami anggap dapat
                        menarik minat Anda. Jika Anda adalah pelanggan lama,
                        kami dapat menghubungi Anda secara elektronik atau
                        cara-cara lain dengan informasi tentang produk dan jasa
                        kami. Jika Anda adalah pelanggan baru, dan di mana kami
                        mengizinkan pihak ketiga untuk menggunakan Data Pribadi
                        Anda, kami (atau mereka) dapat menghubungi Anda secara
                        elektronik hanya jika Anda sudah memberikan
                        persetujuan;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memberitahukan Anda tentang perubahan dan
                        promosi
                        pada jasa kami;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memastikan bahwa konten dari layanan Kami
                        disajikan dengan cara yang paling efektif bagi
                        Anda;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memberitahu Anda mengenai segala pembaruan
                        pada
                        layanan atau perubahan pada layanan yang
                        disediakan;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memelihara, mengembangkan, menguji,
                        meningkatkan,
                        dan mempersonalisasikan layanan untuk memenuhi kebutuhan
                        dan preferensi Anda sebagai pengguna;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memantau dan menganalisis aktivitas, perilaku,
                        dan data demografis pengguna termasuk kebiasaan dan
                        penggunaan berbagai layanan yang tersedia di
                        layanan.</span>
                    </p>
                  </li>
                </ol>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p>
                  <span style="color: #3c4858; font-size: 12pt">Informasi yang Kami kumpulkan</span><span
                    style="color: #3c4858; font-size: 12pt"><br /></span><span
                    style="color: #3c4858; font-size: 12pt">Kami akan menggunakan informasi ini:</span>
                </p>
                <ol>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk mengelola layanan dan operasi internal kami,
                        termasuk pencarian sumber masalah (troubleshooting),
                        analisis data, pengujian, penelitian, serta
                        tujuan-tujuan statistik dan survei lainnya;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memperbaiki layanan kami sehingga konten
                        dipastikan dapat disajikan dengan cara yang paling
                        efektif untuk Anda;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk memungkinkan Anda berpartisipasi dalam fitur
                        interaktif layanan kami, ketika Anda inginkan;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">sebagai bagian dari usaha dalam memastikan
                        keselamatan
                        dan keamanan layanan kami;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">untuk mengukur dan memahami efektivitas periklanan
                        yang
                        Kami lakukan kepada Anda dan pihak lain, serta
                        menyajikan iklan produk dan jasa yang relevan bagi
                        Anda;</span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        untuk memberi masukan dan rekomendasi kepada Anda dan pengguna lain dalam layanan Kami mengenai
                        produk dan jasa yang dapat menarik minat Anda dan mereka.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Informasi yang Kami terima dari sumber lain
                  </span>
                  <span style="color: #3c4858; font-size: 12pt"><br /></span>
                  <span style="color: #3c4858; font-size: 12pt">
                    Kami dapat menggabungkan informasi yang kami terima dari sumber lain dengan informasi yang Anda
                    berikan dan informasi yang kami kumpulkan. Kami dapat menggunakan informasi ini maupun informasi
                    gabungan untuk tujuan yang diatur di atas (tergantung tipe informasi yang kami terima).
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol start="3">
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p>
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  PENGUNGKAPAN INFORMASI PRIBADI
                </span>
              </strong>
            </p>
            <ol>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Kami dapat membagi atau menyingkap Data Pribadi dengan anggota kelompok usaha kami, yang melingkupi
                    cabang dan anak perusahaan, serta perusahaan induk utama dan anak perusahaannya.
                  </span>
                </p>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Kami dapat membagi Data Pribadi dengan pihak ketiga,
                    termasuk :
                  </span>
                </p>
                <ol>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Mitra bisnis, pemasok, dan sub-kontraktor dalam penyelenggarakan kontrak yang kami laksanakan
                        dengan mereka atau Anda.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Pemasang iklan dan jaringan iklan yang membutuhkan data untuk memilih dan menawarkan iklan yang
                        relevan bagi Anda dan pengguna lain. Kami tidak membuka informasi tentang individu yang dapat
                        diidentifikasi, namun kami dapat menyediakan mereka informasi agregat tentang pengguna kami
                        (misalnya informasi 1 juta wanita berusia di bawah 25-26 tahun telah mengakses tautan iklan
                        mereka pada hari tertentu). Kami juga dapat memberikan informasi agregat untuk membantu pemasang
                        iklan dalam menjangkau target audiens tertentu (misalnya, Laki-laki di Sidoarjo). Kami dapat
                        menggunakan data personal yang kami kumpulkan untuk memenuhi permintaan pemasang iklan dengan
                        menampilkan iklan mereka kepada target audiens tersebut.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Penyedia analisis dan mesin pencari yang membantu Kami untuk memperbaiki dan mengoptimasi
                        layanan kami.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p>
                  <span style="color: #3c4858; font-size: 12pt">
                    Kami dapat mengungkap informasi kepada pihak ketiga:
                  </span>
                </p>
                <ol>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Dalam situasi di mana kami menjual atau membeli perusahaan dan/atau aset, kami dapat menyingkap
                        data kepada calon pembeli atau penjual dari perusahaan atau aset tersebut. Jika PT Kayana
                        Digdaya Sejahtera atau perusahaan induknya atau aset-aset substansial yang terkait di dalamnya
                        diperoleh oleh pihak ketiga, maka Data Personal yang dimiliki tentang pelanggan kami akan
                        menjadi salah satu aset yang dipindahtangankan.
                      </span>
                      <span style="color: #3c4858; font-size: 12pt"><br /></span>
                      <span style="color: #3c4858; font-size: 12pt">
                        Dalam hal kami berbagi Informasi dengan afiliasi, kami akan melakukannya dengan maksud agar
                        mereka membantu kami dalam menyediakan layanan, untuk mengoperasikan bisnis kami (termasuk,
                        ketika Anda berlangganan milis kami, untuk tujuan pemasaran langsung), atau untuk tujuan
                        pengolahan data atas nama kami. Semua afiliasi kami berkomitmen untuk mengolah Informasi Pribadi
                        yang mereka terima dari Kami sesuai dengan Kebijakan Privasi dan peraturan perundang-undangan
                        yang berlaku ini.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Jika kami berada di bawah tanggung jawab untuk menyingkap atau membagi data guna mematuhi
                        kewajiban hukum dan perjanjian lain; atau melindungi hak, harta benda, maupun keamanan dari PT
                        Kayana Digdaya Sejahtera, pelanggan kami, dan lain-lain. Hal ini mencakup penukaran informasi
                        dengan perusahaan dan organisasi lain untuk tujuan perlindungan dari penipuan dan pengurangan
                        risiko kredit.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Jika disyaratkan atau diotorisasikan oleh peraturan perundang-undangan yang berlaku (termasuk
                        namun tidak terbatas pada menanggapi pertanyaan terkait regulasi, penyelidikan atau pedoman,
                        atau mematuhi persyaratan atau ketentuan pengarsipan dan pelaporan berdasarkan undang-undang),
                        untuk tujuan yang ditentukan dalam peraturan perundang-undangan yang berlaku;
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Jika terdapat proses hukum dalam bentuk apapun antara Anda dengan kami, atau antara Anda dengan
                        pihak lain, sehubungan dengan, atau terkait dengan layanan/aplikasi, untuk keperluan proses
                        hukum tersebut;
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Sehubungan dengan segala proses verifikasi yang menurut kami atau penyedia layanan/aplikasi
                        perlu sebelum kami mendaftarkan Anda sebagai pengguna, termasuk proses mengenal pelanggan (Know
                        Your Customer);
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Ketika informasi tidak perlu dikaitkan dengan Anda, kami akan melakukan upaya yang wajar untuk
                        menghapus dikaitkannya informasi tersebut dengan Anda sebagai individu sebelum mengungkapkan
                        atau berbagi informasi tersebut.
                      </span>
                    </p>
                  </li>
                  <li style="
                      list-style-type: decimal;
                      color: #3c4858;
                      font-size: 12pt;
                    ">
                    <p style="text-align: justify">
                      <span style="color: #3c4858; font-size: 12pt">
                        Kami tidak akan menjual atau menyewakan Informasi Pribadi. Selain sebagaimana diatur dalam
                        Kebijakan Privasi ini, kami dapat mengungkapkan dan membagikan informasi Anda jika kami
                        memberitahu Anda dan kami telah memperoleh persetujuan Anda untuk pengungkapan atau pembagian
                        tersebut.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">PENYIMPANAN INFORMASI PRIBADI
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Seluruh Informasi Pribadi yang Anda berikan kepada kami disimpan di server yang aman. Semua transaksi
                pembayaran pada layanan akan dienkripsi. Dengan menyerahkan Informasi Pribadi Anda pada layanan, Anda
                menyetujui pengalihan, penyimpanan, serta pengolahan yang terjadi pada layanan Kami. Kami akan mengambil
                langkah-langkah dalam batas kewajaran yang diperlukan untuk memastikan bahwa Informasi Pribadi tersebut
                diperlakukan dengan aman dan sesuai dengan Kebijakan Privasi serta Peraturan yang Berlaku.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">Seluruh Informasi Pribadi yang Anda berikan akan Kami
                simpan: (i) selama Anda masih menjadi pengguna dari layanan kami dan (ii) setidaknya 5 (lima) tahun
                sejak tanggal di mana Anda berhenti menggunakan layanan kami; atau (iii) sesuai dengan tujuan awal dari
                pengumpulan Informasi Pribadi tersebut.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">Dalam situasi di mana kami memberikan (atau Anda memilih)
                sebuah kata sandi (password) yang mengizinkan Anda untuk mengakses bagian-bagian tertentu pada layanan
                kami, Anda bertanggung jawab untuk menjaga kerahasiaan kata sandi ini. Kami meminta Anda untuk tidak
                membagi kata sandi dengan siapa pun.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  HAK ANDA
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Anda dapat memohon untuk penghapusan Informasi Pribadi Anda pada layanan atau menarik persetujuan Anda
                untuk setiap atau segala pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda dengan
                memberikan kepada Kami pemberitahuan yang wajar secara tertulis melalui WhatsApp Chat di 081323038500.
                Tergantung pada keadaan dan sifat permohonan yang Anda minta, Anda harus memahami dan mengakui bahwa
                setelah penarikan persetujuan atau permohonan penghapusan tersebut, Anda mungkin tidak lagi dapat
                menggunakan layanan. Penarikan persetujuan Anda dapat mengakibatkan penghentian akun Anda atau hubungan
                kontraktual Anda dengan kami, dengan semua hak dan kewajiban yang muncul sepenuhnya harus dipenuhi.
                Setelah menerima pemberitahuan untuk menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan
                Informasi Pribadi Anda, kami akan menginformasikan Anda tentang konsekuensi yang mungkin terjadi dari
                penarikan tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin menarik persetujuan atau
                tidak.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Tunduk pada peraturan perundang-undangan yang berlaku, Anda dapat meminta kepada Kami untuk mengakses
                dan/atau mengoreksi Informasi Anda yang berada dalam kepemilikan dan penguasaan kami, dengan menghubungi
                kami. Kami berhak menolak permintaan Anda untuk mengakses, atau untuk memperbaiki, sebagian atau semua
                Informasi Pribadi Anda yang kami miliki atau kuasai jika diizinkan atau diperlukan berdasarkan
                perundang-undangan yang berlaku. Hal ini termasuk dalam keadaan di mana Informasi tersebut dapat berisi
                referensi kepada orang lain atau di mana permintaan untuk akses atau permintaan untuk mengoreksi adalah
                untuk alasan yang Kami anggap tidak relevan, tidak serius, atau menyulitkan.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Layanan kami dapat, dari waktu ke waktu, memuat link menuju dan dari situs-situs milik jaringan mitra,
                pemuat iklan, dan afiliasi lainnya. Jika Anda mengikuti link ke salah satu situs tersebut, mohon
                perhatikan bahwa situs-situs tersebut memiliki Kebijakan Privasi mereka sendiri dan bahwa kami tidak
                bertanggung jawab atau memiliki kewajiban apa pun atas kebijakan-kebijakan tersebut. Mohon periksa
                kebijakan-kebijakan tersebut sebelum Anda menyerahkan informasi apa pun ke situs-situs tersebut.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  TEMPAT KAMI MENYIMPAN INFORMASI PRIBADI
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Informasi dari Anda yang kami kumpulkan dapat disimpan, ditransfer, atau diolah oleh Vultr. Kami akan
                menggunakan semua upaya yang wajar untuk memastikan bahwa Vultr memberikan tingkat perlindungan yang
                sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  KEAMANAN INFORMASI PRIBADI
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Kerahasiaan Informasi Anda adalah hal yang terpenting bagi kami. Kami akan memberlakukan upaya terbaik
                untuk melindungi dan mengamankan data dan Informasi Anda dari akses, pengumpulan, penggunaan atau
                pengungkapan oleh orang-orang yang tidak berwenang dan dari pengolahan yang bertentangan dengan hukum,
                kehilangan yang tidak disengaja, pemusnahan dan kerusakan atau risiko serupa. Namun, pengiriman
                informasi melalui internet tidak sepenuhnya aman. Walau kami akan berusaha sebaik mungkin untuk
                melindungi Informasi Pribadi Anda, Anda mengakui bahwa kami tidak dapat menjamin keutuhan dan keakuratan
                Informasi Pribadi apa pun yang Anda kirimkan melalui Internet, atau menjamin bahwa Informasi Pribadi
                tersebut tidak akan dicegat, diakses, diungkapkan, diubah atau dihancurkan oleh pihak ketiga yang tidak
                berwenang, karena faktor-faktor di luar kendali kami. Anda bertanggung jawab untuk menjaga kerahasiaan
                detail akun Anda, termasuk kata sandi Anda dengan siapapun dan harus selalu menjaga dan bertanggung
                jawab atas keamanan perangkat yang Anda gunakan.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  KEBIJAKAN COOKIES
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Ketika Anda menggunakan layanan, kami dapat menempatkan sejumlah cookies pada browser Anda. Cookies
                adalah sebuah berkas digital kecil berisi huruf dan angka yang kami simpan pada browser atau hard drive
                komputer Anda atas persetujuan Anda. Cookies mengandung informasi yang dipindahkan ke diska keras
                komputer Anda.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Cookies dapat digunakan untuk tujuan berikut: (1) mengaktifkan fungsi tertentu, (2) memberikan analisis,
                (3) menyimpan preferensi Anda; dan (4) memungkinkan pengiriman iklan dan pengiklanan berdasarkan
                perilaku. Beberapa cookies ini hanya akan digunakan jika Anda menggunakan fitur tertentu, atau memilih
                preferensi tertentu, sementara sebagian Cookies lain akan selalu digunakan.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Kami menggunakan cookies untuk alasan-alasan berikut:
              </span>
            </p>
            <ol>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Cookies dibutuhkan untuk pengoperasian layanan kami. Ini termasuk, misalnya, cookies yang
                    memungkinkan Anda memasuki area yang aman di layanan kami, menggunakan keranjang belanja, ataupun
                    menggunakan layanan penagihan elektronik.
                  </span>
                </p>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Cookies memungkinkan kami untuk mengenali dan menghitung jumlah pengunjung serta melihat bagaimana
                    pengunjung bergerak di sekitar layanan kami saat mereka menggunakannya. Ini membantu kami
                    memperbaiki cara kerja layanan kami, misalnya, dengan memastikan pengguna menemukan apa yang mereka
                    cari dengan mudah.
                  </span>
                </p>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Cookies digunakan untuk mengenali Anda saat kembali ke layanan kami. Ini memungkinkan kami melakukan
                    personalisasi terhadap konten kami untuk Anda, menyapa Anda dengan nama, serta mengingat preferensi
                    Anda (misalnya, pilihan bahasa atau wilayah Anda).
                  </span>
                </p>
              </li>
              <li style="
                  list-style-type: decimal;
                  color: #3c4858;
                  font-size: 12pt;
                ">
                <p style="text-align: justify">
                  <span style="color: #3c4858; font-size: 12pt">
                    Cookies mencatat kunjungan Anda ke layanan kami, halaman yang telah Anda kunjungi, serta tautan yang
                    telah Anda ikuti. Kami akan menggunakan informasi ini untuk membuat layanan Kami serta iklan yang
                    terpasang di dalamnya lebih relevan kepada minat Anda. Kami juga dapat membagi informasi ini dengan
                    pihak ketiga untuk tujuan tersebut.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <span style="color: #3c4858; font-size: 12pt">
                Mohon perhatikan bahwa pihak ketiga (termasuk, misalnya, jaringan periklanan dan penyedia jasa eksternal
                seperti jasa analisis lalu lintas web) juga dapat menggunakan cookies ini, di mana kami tidak memiliki
                kendali. Cookies ini cenderung membuat layanan kami dan iklan yang ditampilkan di dalamnya lebih relevan
                dengan minat Anda, serta meningkatkan kinerja layanan kami.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Anda dapat menghapus Cookies dengan cara melakukan fungsi clear data pada mobile app maupun web browser
                Anda yang memungkinkan Anda untuk menolak pengaturan seluruh atau sebagian cookies. Akan tetapi, Anda
                mungkin tidak dapat mengakses seluruh atau sebagian layanan kami.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  PENGAKUAN DAN PERSETUJUAN
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Dengan menyetujui Kebijakan Privasi, Anda mengakui bahwa Anda telah membaca dan memahami Kebijakan
                Privasi ini dan menyetujui segala ketentuannya. Secara khusus, Anda setuju dan memberikan persetujuan
                kepada Kami untuk mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau
                mengolah Informasi Pribadi Anda sesuai dengan Kebijakan Privasi ini.
              </span>
              <span style="color: #3c4858; font-size: 12pt"><br /></span>
              <span style="color: #3c4858; font-size: 12pt">
                Dalam hal Anda memberikan Informasi Pribadi yang berkaitan dengan individu lain (misalnya Informasi
                Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau pihak lain) kepada kami, maka
                Anda menyatakan dan menjamin bahwa Anda telah memperoleh persetujuan dari individu tersebut untuk, dan
                dengan ini menyetujui atas nama individu tersebut untuk, pengumpulan, penggunaan, pengungkapan dan
                pengolahan Informasi Pribadi mereka oleh kami.
              </span>
            </p>
          </li>
          <!-- <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  MATERI PEMASARAN
                </span>
              </strong>
              <strong
                ><span style="color: #3c4858; font-size: 12pt"><br /></span
              ></strong>
              <span style="color: #3c4858; font-size: 12pt"
                >Kami dan Mitra Kami dapat mengirimkan anda dan/atau orang tua,
                wali atau pengampu Anda pemasaran langsung, iklan, dan
                komunikasi promosi melalui aplikasi push-notification, pesan
                melalui Aplikasi, pos, panggilan telepon, layanan pesan singkat,
                email dan/atau aplikasi pesan lainnya (&ldquo;Materi
                Pemasaran&rdquo;) jika Anda telah setuju untuk berlangganan
                milis kami, dan/atau setuju untuk menerima materi pemasaran dan
                promosi dari kami. Anda dapat memilih untuk tidak menerima
                komunikasi pemasaran tersebut kapan saja dengan menghubungi kami
                melalui email prakerja@Profesi. Mohon perhatikan bahwa jika Anda
                memilih untuk keluar, kami masih dapat mengirimi Anda
                pesan-pesan non-promosi, seperti tanda terima atau informasi
                tentang Akun Anda.
              </span>
            </p>
          </li> -->
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  PERUBAHAN DALAM KEBIJAKAN PRIVASI KAMI
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan kami sendiri dari waktu ke waktu,
                untuk memastikan bahwa Kebijakan Privasi ini konsisten dengan perkembangan kami di masa depan, dan/atau
                perubahan persyaratan hukum atau peraturan. Perubahan apa pun yang kami lakukan terhadap Kebijakan
                Privasi Kami di masa depan akan diterbitkan melalui halaman ini dan, ketika dibutuhkan, melalui
                pemberitahuan umum yang dipublikasikan pada layanan dan/atau situs web, atau sebaliknya ke alamat e-mail
                Anda yang tercantum dalam Akun Anda. Mohon kunjungi kembali halaman ini dari waktu ke waktu untuk
                melihat adanya pemutakhiran atau perubahan pada Kebijakan Privasi kami. Anda setuju bahwa Anda
                bertanggung jawab untuk meninjau Kebijakan Privasi ini secara teratur untuk informasi terbaru tentang
                pengolahan data dan praktik perlindungan data Kami, dan bahwa dengan Anda terus menggunakan layanan atau
                situs web Kami, berkomunikasi dengan Kami, atau mengakses dan menggunakan layanan setelah adanya
                perubahan apapun terhadap Kebijakan Privasi ini akan dianggap sebagai persetujuan Anda terhadap
                Kebijakan Privasi ini dan segala perubahannya.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  DATA ANONIM
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan Informasi, yang tersedia, dengan
                catatan, (i) bahwa semua hal yang dapat mengidentifikasi telah dihapus sehingga data, baik sendiri atau
                dikombinasi dengan data lain yang tersedia, tidak dapat dihubungkan dengan atau dikaitkan dengan atau
                tidak dapat mengidentifikasi suatu individu, dan (ii) data serupa telah digabungkan sehingga data asli
                membentuk bagian dari kumpulan data yang lebih besar.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt"> BACKUP </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Sistem dan database kami diback-up secara rutin dan disimpan di beberapa tempat serta menerapkan
                teknologi firewall untuk memastikan bahwa servis Kami dan informasi Anda selalu aman dan tersedia, apa
                pun yang terjadi.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  AKSES DATA CUSTOMER
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Kami menerapkan teknologi enkripsi dalam menjaga keamanan data dan informasi yang tersimpan sehingga
                dapat menjamin bahwa data Anda tidak akan diretas, dicuri, ataupun digunakan oleh pihak-pihak yang tidak
                bertanggung jawab yang tidak berhubungan dengan TEFLIN.
              </span>
            </p>
          </li>
          <li style="list-style-type: decimal; color: #3c4858; font-size: 12pt">
            <p style="text-align: justify">
              <strong>
                <span style="color: #3c4858; font-size: 12pt">
                  CARA MENGHUBUNGI KAMI
                </span>
              </strong>
              <strong><span style="color: #3c4858; font-size: 12pt"><br /></span></strong>
              <span style="color: #3c4858; font-size: 12pt">
                Jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau Anda ingin mendapatkan akses dan/atau
                melakukan koreksi terhadap Informasi Pribadi Anda, silahkan dapat menghubungi Kami melalui WhatsApp Chat
                di 081323038500.
              </span>
            </p>
          </li>
        </ol>
        <p><br /></p>
        <p><br /></p>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data: () => {
    return {
      Kebijakan_Privasi_ini_mencakup_hal_hal_sebagai_berikut: [
        'Informasi Pribadi yang Dapat Kami Kumpulkan',
        'Penggunaan Informasi Pribadi',
        'Pengungkapan Informasi Pribadi',
        'Penyimpanan Informasi Pribadi',
        'Hak Anda',
        'Tempat Kami Menyimpan Informasi Anda',
        'Keamanan Informasi Pribadi',
        'Kebijakan Cookies',
        'Pengakuan dan Persetujuan',
        'Materi Pemasaran',
        'Perubahan dalam Kebijakan Privasi Kami',
        'Data Anonim',
        'Backup',
        'Akses Data Customer',
        'Cara Menghubungi Kami',
      ],
    }
  }
}
</script>
