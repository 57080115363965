<template>
  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
    <v-col v-if="isLoadingActivity" cols="12" class="text-center pa-4">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-col>

    <template v-else-if="detail">
      <div class="mb-3 font-weight-bold text-capitalize ms-3 text-h6">
        {{ detail.title }}
      </div>

      <v-card class="col-12" elevation="0">
        <div v-if="activeSession == 'result'" class="pa-4">
          <v-row class="py-4" justify="center">
            <v-col cols="8" class="justify-center d-flex">
              <v-icon class="d-block" color="grey lighten-2" size="96"
                >ri-file-edit-fill</v-icon
              >
            </v-col>

            <v-col v-if="detail.type == 'exam'" cols="10" class="mx-auto">
              <v-data-table
                class="custom-table"
                :headers="getExamHeaders"
                :items="detail.section"
                hide-default-footer
                hide-default-sort-column
              >
                <template v-slot:[`item.result_score`]="{ item }">
                  {{ item.score >= item.min_score ? "Lulus" : "Tidak Lulus" }}
                </template>
              </v-data-table>
            </v-col>

            <template v-else>
              <v-col cols="8" class="d-flex justify-space-between">
                <div>Kesempatan mengulang</div>
                <div>
                  {{
                    repeatRemaining == -1
                      ? "Tanpa Batas"
                      : repeatRemaining + "x"
                  }}
                </div>
              </v-col>
              <v-col
                v-if="detail.settings.set_minimum_value.is_set"
                cols="8"
                class="d-flex justify-space-between"
              >
                <div>Nilai minimal</div>
                <div>
                  {{ detail.settings.set_minimum_value.minimum_value }}
                </div>
              </v-col>
              <v-col cols="8">
                <v-divider></v-divider>
              </v-col>
            </template>

            <v-col v-if="detail.settings.is_show_score" cols="8">
              <div class="text-center font-weight-bold">Nilai</div>
              <div class="text-center primary--text">
                <span class="text-h3 font-weight-bold">
                  {{ detail.score }}
                </span>
                <span
                  class="text-h4 font-weight-bold"
                  v-if="detail.settings.set_minimum_value.is_set"
                  >/{{ detail.settings.set_minimum_value.minimum_value }}</span
                >
              </div>

              <template v-if="detail.type == 'exam'">
                <div class="mt-2 font-weight-bold text-center">
                  Keterangan: {{ isPassed ? "Lulus" : "Tidak Lulus" }}
                </div>

                <div class="mt-2 text-center">
                  <span>Kesempatan mengulang:</span>
                  <span>
                    {{
                      repeatRemaining == -1
                        ? "Tanpa Batas"
                        : repeatRemaining + "x"
                    }}
                  </span>
                </div>
              </template>
            </v-col>

            <v-col md="6" sm="12">
              <v-btn
                v-if="detail.settings.is_show_discussion"
                :loading="isLoadingExplanation"
                rounded
                block
                color="primary"
                elevation="0"
                large
                @click="handleShowExplanation"
                >Lihat Jawaban</v-btn
              >
              <v-spacer class="py-2"></v-spacer>

              <div v-if="repeatRemaining > 0 || repeatRemaining == -1">
                <v-btn
                  rounded
                  block
                  text
                  color="primary"
                  elevation="0"
                  large
                  @click="handleRepeatTest"
                  :loading="isLoadingRepeat"
                >
                  <span v-if="detail.type == 'pre_test'">Ulangi Pre Test</span>
                  <span v-else-if="detail.type == 'quiz'">Ulangi Kuis</span>
                  <span v-else-if="detail.type == 'post_test'"
                    >Ulangi Post Test</span
                  >
                  <span v-else-if="detail.type == 'exam'">Ulangi Test</span>
                </v-btn>
              </div>
              <v-spacer class="py-2"></v-spacer>
              <v-btn
                v-if="isCanContinue"
                :loading="$props.isLoadingNext"
                color="primary"
                depressed
                rounded
                block
                large
                @click="handleNextActivity"
              >
                Klik aktivitas selanjutnya
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="activeSession == 'question'" class="pa-4">
          <v-col v-if="isLoadingQuestion" cols="12" class="text-center pa-4">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>

          <template v-else-if="currentQuestion">
            <div
              class="px-4 text-right"
              v-if="!isReview && currentQuestion.data.timer.active"
            >
              <v-btn depressed x-small dark class="rounded-lg" color="red">
                Pengerjaan Soal : {{ timerQuestionSeconds | minutesAndSeconds }}
              </v-btn>
            </div>
            <div class="pa-4 row align-center navy-soft--text">
              <div
                id="box-navigasi"
                v-if="detail.type != 'exam'"
                :class="
                  $vuetify.breakpoint.name == 'xs'
                    ? 'col-md-4 d-flex flex-colmun align-center'
                    : 'col-md-4 d-flex'
                "
                style="gap: 8px"
              >
                <v-btn
                  depressed
                  small
                  color="#EBF5FF"
                  class="mr-1"
                  @click="handlePrevious"
                  :disabled="!isCanPrevious()"
                  style="min-width: auto; width: 30px !important"
                >
                  <v-icon color="primary">$prev</v-icon></v-btn
                >
                <div class="mt-2 mr-1 text-center">
                  Soal no
                  <span class="primary--text">{{ current + 1 }}</span>
                  dari {{ detail.total_question }}
                </div>
                <v-btn
                  small
                  depressed
                  color="#EBF5FF"
                  :disabled="!isCanNext()"
                  @click="handleNext"
                  style="min-width: auto; width: 30px !important"
                >
                  <v-icon color="primary">$next</v-icon>
                </v-btn>
              </div>

              <v-sheet v-else class="pa-2 col-12">
                <v-slide-group show-arrows>
                  <v-slide-item
                    v-for="index in detail.total_question"
                    :key="index"
                  >
                    <v-btn
                      :class="getQuestionStatus(index)"
                      x-small
                      outlined
                      :disabled="!isCanSkip"
                      class="py-3 mx-2 my-2 text-justify fs-12"
                      style="width: 35px"
                      elevation="0"
                      @click="handleGoTo(index - 1)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <div :class="detail.type == 'exam' ? 'col-md-8' : 'col-md-4'">
                <div
                  v-if="!isReview && detail.settings.is_show_timer"
                  class="mb-0 fs-16 color-black"
                  :class="detail.type != 'exam' ? 'text-center' : ''"
                  color="primary"
                >
                  <v-icon left>mdi-clock-time-three-outline</v-icon>
                  Sisa Waktu Berlangsung:
                  {{ timerActivitySeconds | minutesAndSeconds }}
                </div>
              </div>
              <div class="justify-end col-md-4 align-end text-end">
                <v-tooltip v-if="!isReview" color="grey darken-3" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="current + 1 !== detail.total_question"
                      :loading="isLoadingComplete"
                      depressed
                      :block="$vuetify.breakpoint.name == 'xs'"
                      v-bind="attrs"
                      v-on="on"
                      class="rounded-lg"
                      color="primary"
                      small
                      @click="setActivityAnswer()"
                    >
                      Submit
                    </v-btn>
                  </template>
                  <span>Submit</span>
                </v-tooltip>
                <v-btn
                  v-else
                  depressed
                  :block="$vuetify.breakpoint.name == 'xs'"
                  class="rounded-lg"
                  color="primary"
                  small
                  @click="handleBack"
                >
                  Kembali
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="pa-4 d-flex" style="user-select: none">
              <div class="navy-soft--text" style="width: 2rem">
                {{ current + 1 }}.
              </div>
              <div class="col pa-0">
                <div
                  id="tagImg"
                  class="text-justify"
                  v-html="currentQuestion.data.question.text"
                ></div>

                <div
                  v-if="
                    currentQuestion.data.question.media &&
                    currentQuestion.data.question.media[0]
                  "
                >
                  <AudioPlayer
                    class="d-inline-block"
                    :src="currentQuestion.data.question.media[0].public_url"
                    :repeat_audio="playCount"
                    :is-review="isReview"
                    @play="setPlayCount"
                  />
                </div>

                <!-- if not reviewing -->
                <v-radio-group
                  v-if="!isReview"
                  :disabled="!isCanAnswer"
                  hide-details="auto"
                  v-model="currentQuestion.data.answer"
                  class="options"
                >
                  <v-radio
                    v-for="(item, index) in currentQuestion.data.options"
                    :key="index"
                    class="mb-2"
                    :value="index"
                  >
                    <template v-slot:label>
                      <div style="display: block">
                        <div v-html="item.text"></div>
                        <img
                          width="250px"
                          height="auto"
                          class="mt-1 rounded-lg"
                          :src="item.media[0].public_url"
                          v-if="item.media.length > 0"
                        />
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>

                <!-- else if reviewing -->
                <template v-else>
                  <v-list
                    v-if="detail.settings.is_show_discussion"
                    class="options"
                    disabled
                  >
                    <v-list-item-group multiple class="snow">
                      <v-list-item
                        class="px-4 py-3 mb-4 overflow-hidden rounded"
                        v-for="(item, index) in currentQuestion.data.options"
                        :key="index"
                        :color="
                          item.text == currentAnswer.correct_answer[0]
                            ? 'green'
                            : item.text == currentAnswer.answer[0]
                            ? 'error'
                            : ''
                        "
                      >
                        <v-list-item-content
                          class=""
                          :class="{
                            'font-weight-bold':
                              currentAnswer.answer[0] == item.text,
                            'error--text':
                              currentAnswer.answer[0] == item.text &&
                              item.text != currentAnswer.correct_answer[0],
                            'success--text':
                              currentAnswer.correct_answer[0] == item.text &&
                              item.text == currentAnswer.correct_answer[0],
                          }"
                        >
                          <div v-html="item.text" />
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon>
                            {{
                              item.text == currentAnswer.correct_answer[0]
                                ? "ri-checkbox-circle-fill success--text"
                                : item.text == currentAnswer.answer[0]
                                ? "ri-close-circle-fill error--text"
                                : ""
                            }}
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>

                  <v-alert
                    shaped
                    dense
                    prominent
                    icon="ri-spam-2-fill"
                    type="error"
                    v-show="!currentQuestion.answer.length"
                  >
                    Soal ini tidak dijawab.
                  </v-alert>

                  <div>
                    <div class="font-weight-bold">Pembahasan soal:</div>
                    <v-list class="options" disabled>
                      <v-list-item-group class="snow" color="primary">
                        <v-list-item
                          class="px-4 py-3 mb-4 overflow-hidden rounded"
                        >
                          <v-list-item-content>
                            <div
                              class="navy-soft--text"
                              v-html="
                                currentQuestion.data.explanation.correct.text
                              "
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </v-card>
    </template>

    <v-snackbar v-model="isShowAlert" outlined top>
      <div v-html="alertMessage"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="isShowAlert = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import {
  actGetActivityAnswer,
  actGetActivityDetail,
  actGetActivityQuestion,
  actSetActivityAnswer,
  actSetActivityComplete,
  actSetActivityDone,
} from "../../services/activity";
import { postCheckRepeatQuestion } from "../../services/my-program";
import { actGetQuestionMultiple } from "../../services/question";

import AudioPlayer from "@/views/_inc/media/AudioPlayer.vue";

export default {
  components: { AudioPlayer },
  props: {
    classId: String,
    topicId: String,
    actId: String,
    isLoadingNext: Boolean,
  },
  data: () => ({
    isLoadingActivity: undefined,
    isLoadingComplete: undefined,
    isLoadingQuestion: undefined,
    isLoadingRepeat: undefined,
    isLoadingExplanation: undefined,
    isShowAlert: undefined,

    alertMessage: undefined,
    activeSession: undefined,
    isReview: undefined,

    timerActivity: undefined,
    timerActivitySeconds: undefined,

    timerQuestion: undefined,
    timerQuestionSeconds: undefined,

    detail: undefined,
    questions: undefined,
    answers: undefined,
    current: 0,
  }),
  computed: {
    isCanContinue() {
      if (this.detail.is_complete) return false;
      const condition = this.detail.settings.set_minimum_value;
      return condition.is_set
        ? this.detail.score >= condition.minimum_value
        : true;
    },
    isPassed() {
      if (!this.detail || this.detail.type != "exam") return;
      return this.detail.section?.every((v) => v.score >= v.min_score);
    },
    repeatRemaining() {
      if (!this.detail) return;
      if (this.detail.settings.opportunity_repeat_test == -1) return -1;
      return (
        this.detail.settings.opportunity_repeat_test - this.detail.repeat_test
      );
    },
    currentQuestion() {
      if (!this.questions) return;
      return this.questions[this.current];
    },
    currentAnswer() {
      if (!this.answers || !this.currentQuestion) return;
      return this.answers.find(
        (v) => v.question_id == this.currentQuestion.question_id
      );
    },
    playCount() {
      if (!this.currentQuestion) return;
      if (this.currentQuestion.setting.repeat.opportunity == null) return null;
      return (
        1 +
        this.currentQuestion.setting.repeat.opportunity -
        this.currentQuestion.setting.repeat.used_count
      );
    },
    isCanSkip() {
      if (this.isReview) return true;
      return this.detail.settings.is_skip;
    },
    isCanAnswer() {
      if (this.currentQuestion.data.timer.active)
        return this.timerQuestionSeconds > 0;
      if (this.detail.is_show_timer) return this.timerActivitySeconds > 0;
      return true;
    },
    getExamHeaders() {
      return [
        { text: "Jenis Test", value: "name", align: "start", sortable: false },
        {
          text: "Nilai Minimal",
          value: "min_score",
          align: "center",
          sortable: false,
        },
        {
          text: "Hasil Nilai Test",
          value: "score",
          align: "center",
          sortable: false,
        },
        {
          text: "Keterangan",
          value: "result_score",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.getActivityDetail();
  },
  beforeDestroy() {
    clearInterval(this.timerActivity);
    clearInterval(this.timerQuestion);
  },
  methods: {
    isCanPrevious() {
      if (this.isReview) return true;
      if (
        !this.detail.settings.is_skip &&
        this.currentQuestion.data.answer == undefined
      )
        return false;

      return this.current > 0;
    },
    isCanNext() {
      if (this.isReview) return true;
      if (
        !this.detail.settings.is_skip &&
        this.currentQuestion.data.answer == undefined
      )
        return false;
      return this.current + 1 < this.detail.total_question;
    },
    handleError(error) {
      this.alertMessage = error.response?.data
        ? error.response.data.message
        : error.message;
      this.isShowAlert = true;
      throw error;
    },
    async getActivityDetail() {
      this.isLoadingActivity = true;
      const {
        data: { data },
      } = await actGetActivityDetail({
        class_id: this.$props.classId,
        topic_id: this.$props.topicId,
        act_id: this.$props.actId,
      })
        .catch((e) => {
          this.isLoadingActivity = false;
          this.handleError(e);
        })
        .finally(() => {});

      this.detail = data;

      await this.getActivityQuestion().catch((e) => {
        this.isLoadingActivity = false;
        this.handleError(e);
      });
      this.isLoadingActivity = false;

      this.activeSession =
        this.detail.is_complete || this.detail.repeat_test > 0
          ? "result"
          : "question";

      if (this.activeSession == "question") {
        this.current = 0;
        this.startTimerActivity();
      }
    },
    async getActivityQuestion() {
      this.isLoadingQuestion = true;
      const {
        data: { data: data1 },
      } = await actGetActivityQuestion({
        class_id: this.$props.classId,
        topic_id: this.$props.topicId,
        act_id: this.$props.actId,
      }).catch((e) => {
        this.isLoadingQuestion = false;
        this.handleError(e);
      });

      if (this.detail.type == "exam") {
        this.questions = data1.reduce((cur, car) => {
          const list = car.groups.reduce((cur2, car2) => {
            const list = car2.list_question.map((v) => ({
              ...v,
              section_id: car.id,
              group_id: car2.group_id,
            }));
            return [...cur2, ...list];
          }, []);

          return [...cur, ...list];
        }, []);
      } else {
        this.questions = data1.reduce((cur, car) => {
          const list = car.list_question.map((v) => ({
            ...v,
            group_id: car.group_id,
          }));
          return [...cur, ...list];
        }, []);
      }

      const {
        data: { data: data2 },
      } = await actGetQuestionMultiple({
        id: this.questions.map((v) => v.question_id),
      });

      this.questions.forEach((v) => {
        const question = data2.find((vv) => vv.id == v.question_id);
        v.data = question;
      });

      this.isLoadingQuestion = false;
    },
    async setActivityDone() {
      const payload = {
        member_redeem_id: this.$props.classId,
        topic_id: this.$props.topicId,
        activity_id: this.$props.actId,
      };
      await actSetActivityDone(payload);
    },
    async setActivityAnswer() {
      this.isLoadingComplete = true;

      const payload = {
        id: this.$props.classId,
        topic_id: this.$props.topicId,
        activity_id: this.$props.actId,
      };

      if (this.detail.type == "exam") {
        const sections = this.questions.reduce((car, cur) => {
          let section = car.find((v) => v.section_id == cur.section_id);
          if (!section) {
            section = { section_id: cur.section_id, question_group: [] };
            car.push(section);
          }

          let group = section.question_group.find(
            (v) => v.group_id == cur.group_id
          );
          if (!group) {
            group = { group_id: cur.group_id, questions: [] };
            section.question_group.push(group);
          }

          const answer = cur.data.options[cur.data.answer];
          group.questions.push({
            question_id: cur.question_id,
            answer: answer ? [answer.text] : [],
          });
          return car;
        }, []);

        payload.sections = sections;
      } else {
        const question_group = this.questions.reduce((car, cur) => {
          let group = car.find((v) => v.group_id == cur.group_id);
          if (!group) {
            group = { group_id: cur.group_id, questions: [] };
            car.push(group);
          }

          const answer = cur.data.options[cur.data.answer];
          group.questions.push({
            question_id: cur.question_id,
            answer: answer ? [answer.text] : [],
          });

          car.push();

          return car;
        }, []);
        payload.question_group = question_group;
      }

      await actSetActivityAnswer(payload).catch((e) => {
        this.isLoadingComplete = false;
        this.handleError(e);
      });
      this.isShowAlert = true;
      this.alertMessage = "Jawaban Terkirim";
      await this.setActivityDone().catch((e) => {
        this.isLoadingComplete = false;
        this.handleError(e);
      });
      await this.getActivityDetail().catch((e) => {
        this.isLoadingComplete = false;
        this.handleError(e);
      });
      this.isLoadingComplete = false;
      clearInterval(this.timerActivity);
      clearInterval(this.timerQuestion);
    },
    setPlayCount() {
      if (this.isReview) return;
      postCheckRepeatQuestion({
        id: this.$props.classId,
        topic_id: this.$props.topicId,
        activity_id: this.$props.actId,
        group_id: this.currentQuestion.group_id,
        question_id: this.currentQuestion.question_id,
      });
      this.currentQuestion.setting.repeat.used_count++;
    },
    startTimerActivity() {
      if (this.detail.settings.is_show_timer) {
        this.timerActivitySeconds = this.detail.settings.timer * 60;
        clearInterval(this.timerActivity);
        this.startTimerQuestion();
        this.timerActivity = setInterval(() => {
          if (--this.timerActivitySeconds == 0) {
            clearInterval(this.timerActivity);
            this.setActivityAnswer();
          }
        }, 1e3);
      }
    },
    startTimerQuestion() {
      if (this.isReview) return;
      if (this.currentQuestion.data && this.currentQuestion.data.timer.active) {
        this.timerQuestionSeconds = this.currentQuestion.data.timer.duration;

        clearInterval(this.timerQuestion);
        this.timerQuestion = setInterval(() => {
          if (--this.timerQuestionSeconds == 0) {
            clearInterval(this.timerQuestion);
            if (this.current + 1 < this.detail.total_question)
              this.handleNext();
          }
        }, 1e3);
      }
    },
    getQuestionStatus(index) {
      if (index == this.current + 1) return "bg-accent-orange";
      // if (this.questions[index - 1].data.answer)
      return "bg-accent-green";
      // return "bg-accent-default";
    },

    handleBack() {
      this.activeSession = "result";
    },
    handleGoTo(index) {
      this.isLoadingQuestion = true;
      this.$nextTick().then(() => {
        this.isLoadingQuestion = false;
        this.current = index;
      });
      this.startTimerQuestion();
    },
    handleNext() {
      if (this.current + 1 < this.detail.total_question)
        this.handleGoTo(this.current + 1);
    },
    handlePrevious() {
      if (this.current != 0) this.handleGoTo(this.current - 1);
    },
    async handleShowExplanation() {
      this.isLoadingExplanation = true;
      const {
        data: { data },
      } = await actGetActivityAnswer({
        class_id: this.$props.classId,
        topic_id: this.$props.topicId,
        act_id: this.$props.actId,
      })
        .catch((e) => this.handleError(e))
        .finally(() => {
          this.isLoadingExplanation = false;
        });
      this.answers = data;
      this.activeSession = "question";
      this.isReview = true;
      this.current = 0;
    },
    handleRepeatTest() {
      this.isLoadingRepeat = true;
      actSetActivityComplete({
        member_redeem_id: this.$props.classId,
        topic_id: this.$props.topicId,
        activity_id: this.$props.actId,
      })
        .catch((e) => this.handleError(e))
        .finally(() => {
          this.isLoadingRepeat = false;
        });
      this.current = 0;
      this.activeSession = "question";
      this.isReview = false;
      this.startTimerActivity();
    },
    handleNextActivity() {
      this.$emit("click:next");
    },
  },
  filters: {
    minute: (val) => String(val || 0).padStart(2, 0),
    minutesAndSeconds(value) {
      if (!value) value = 0;
      var hours = `${Math.floor(value / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(value / 60) % 60}`.padStart(2, 0);
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${hours} : ${minutes} : ${seconds}`;
    },
  },
};
</script>
