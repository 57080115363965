import axios from "axios";
import { API } from ".";

export const actGetActivityDetail = ({ class_id, topic_id, act_id }) => {
  return axios.get(API.ACTIVITY.DETAIL({ class_id, topic_id, act_id }));
};

export const actGetActivityQuestion = ({ class_id, topic_id, act_id }) => {
  return axios.get(API.ACTIVITY.QUESTION({ class_id, topic_id, act_id }));
};

export const actGetActivityAnswer = ({ class_id, topic_id, act_id }) => {
  return axios.get(API.ACTIVITY.ANSWER({ class_id, topic_id, act_id }));
};

export const actSetActivityComplete = (payload) => {
  return axios.post(API.ACTIVITY.UPDATE_IS_COMPLETE, payload);
};

export const actSetActivityAnswer = (payload) => {
  return axios.post(API.ACTIVITY.SUBMIT_ANSWER, payload);
};

export const actSetActivityDone = (payload) => {
  return axios.post(API.ACTIVITY.DONE, payload);
};
