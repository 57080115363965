var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-2 transparent",attrs:{"elevation":"0"}},[_c('vue-html2pdf',{ref:"certifPeserta",attrs:{"enable-download":false,"preview-modal":true,"show-layout":false,"pdf-quality":2,"filename":_vm.certificateName,"pdf-orientation":"landscape","manual-pagination":true,"pdf-content-width":"100%"},on:{"hasDownloaded":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item",style:({
          position: 'relative',
          backgroundImage: `url('${require('@/assets/images/certificate/bg-certificate.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        })},[(Object.keys(_vm.data).length > 0)?_c('div',{staticClass:"style1"},[_c('div',{staticStyle:{"font-style":"normal","text-align":"left","font-family":"Inter, sans-serif","font-weight":"900","font-size":"40px","line-height":"auto","margin-top":"20%","margin-left":"18%","color":"black"}},[_vm._v(" "+_vm._s(_vm.data.user_name)+" ")]),_c('div',{staticStyle:{"font-style":"normal","font-family":"Inter, sans-serif","font-weight":"700","font-size":"26px","line-height":"20px","margin-top":"8%","margin-left":"18%","color":"black"}},[_vm._v(" "+_vm._s(_vm.data.program_name)+" ")])]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }