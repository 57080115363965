<template>
  <v-card elevation="0" class="px-2 transparent">
    <vue-html2pdf ref="certifPeserta" :enable-download="false" :preview-modal="true" :show-layout="false"
      :pdf-quality="2" :filename="certificateName" pdf-orientation="landscape" :manual-pagination="true"
      pdf-content-width="100%" @hasDownloaded="hasGenerated($event)">
      <section slot="pdf-content">
        <section class="pdf-item" :style="{
          position: 'relative',
          backgroundImage: `url('${require('@/assets/images/certificate/bg-certificate.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div style="
                font-style: normal;
                text-align: left;
                font-family: Inter, sans-serif;
                font-weight: 900;
                font-size: 40px;
                line-height: auto;
                margin-top: 20%;
                margin-left: 18%;
                color: black;
              ">
              {{ data.user_name }}
            </div>

            <div style="
                font-style: normal;
                font-family: Inter, sans-serif;
                font-weight: 700;
                font-size: 26px;
                line-height: 20px;
                margin-top: 8%;
                margin-left: 18%;
                color: black;
              ">
              {{ data.program_name }}
            </div>
          </div>
        </section>
      </section>
      <!-- <section slot="pdf-content">
        <template>
          <section class="pdf-item" :style="{
            position: 'relative',
            backgroundImage: customBg.front,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
            <div style="
                      position: relative;
                      top: 38.5%;
                      color: #000;
                      font-family: 'PT Sans', sans-serif;
                      font-style: normal;
                      font-weight: 900;
                      font-size: 28px;
                      line-height: auto;
                      text-align: center;
                      padding-bottom: 5px;
                    ">
              {{ data.program_name }}
            </div>
            <div style="
                      position: absolute;
                      width: 100%;
                      top: 52%;
                      color: #000;
                      font-family: 'Great Vibes', cursive;
                      font-style: normal;
                      font-weight: 900;
                      font-size: 52px;
                      line-height: auto;
                      text-align: center;
                      padding-bottom: 5px;
                    ">
              {{ data.user_name }}
            </div>
            <div style="
                position: relative;
                top: 58%;
                color: #000;
                font-family: 'PT Sans', sans-serif;
                font-size: 18px;
                text-align: center;
              ">
              Karena telah menyelesaikan pelatihan selama 15 jam pada tanggal <span style="font-weight: bold;">{{
                currentDateTime(data.end_test_at) }}</span>
            </div>
            <div v-if="data.feedback_score > 0" style="
                top: 60%;
                position: relative;
                color: #000;
                font-family: 'PT Sans', sans-serif;
                font-size: 18px;
                text-align: center;
              ">
              <span v-if="data.feedback_score >= 80">
                <strong> LULUS </strong>
                dengan predikat
                <strong> "SANGAT BAIK" </strong>
              </span>
              <span v-else>
                dan dinyatakan
                <strong> LULUS </strong>
              </span>
            </div>

            <div style="
                font-family: 'PT Sans', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 14px;
                text-align: center;
                position: absolute;
                left: 23%;
                bottom: 10%;
                display: inline-block;
              ">
              <vue-qrcode :value="data.certificate_url"
                :options="{ color: { dark: '#4B4B4B' }, width: 100 }"></vue-qrcode>
              <div>{{ data.license }}</div>
            </div>

            <div style="
                font-family: 'PT Sans', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 14px;
                text-align: center;
                position: absolute;
                bottom: 12%;
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 15px;
              " :style="`
                right: ${data.program_name.toLowerCase() != 'Bahan Pendukung Pertanian untuk Petani Terampil'.toLowerCase() ? '28%' : '26%'};
              `">
              <img v-if="data.is_prakerja == 1" :src="require('@/assets/images/prakerja_.png')" width="80"
                alt="logo prakerja">
              <img
                v-if="data.program_name.toLowerCase() == 'Bahan Pendukung Pertanian untuk Petani Terampil'.toLowerCase()"
                :src="require('@/assets/images/logo_AAI.png')" width="60" alt="logo aai">
            </div>
          </section>
          <section class="pdf-item" :style="{
            position: 'relative',
            backgroundImage: customBg.back,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
            <template v-if="!terkustomkustom.includes(data.program_name.toLowerCase())">
              <div style="
                  position: absolute;
                  width: 100%;
                  top: 29%;
                  color: #000;
                  font-family: 'PT Sans', sans-serif;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 28px;
                  line-height: auto;
                  text-align: center;
                  padding-bottom: 5px;
                ">
                {{ data.program_name }}
              </div>
              <div style="
                  position: absolute;
                  width: 100%;
                  top: 40%;
                  color: #000;
                  font-family: 'PT Sans', sans-serif;
                  font-style: normal;
                  font-size: 20px;
                  line-height: auto;
                  text-align: center;
                  padding-bottom: 5px;
                ">
                <div style="display: inline-block; width: 67%">
                  <table style="width: 100%">
                    <thead>
                      <tr>
                        <th>Kompetensi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in data.competence" :key="item">
                        <td>{{ item }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
</section>
</template>
</section> -->
    </vue-html2pdf>
  </v-card>
</template>

<script>
/**
 * README:
 * 
 * CARA UPDATE CUSTOM BG
 * 
 * COPY NAMA PROGRAM
 * PASTE MENGGUNAKAN LOWERCASE DI BAGIAN terkustomkustom (LINE: 257)
 * DOWNLOAD CUSTOM BG
 * UPDATE NAMA FILE DENGAN FORMAT BERIKUT
 * `{NAMA_PROGRAM.toLowerCase()}-2.png'
 * SIMPAN
 */

import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
// import VueQrcode from "@chenfengyuan/vue-qrcode";
// import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  components: {
    VueHtml2pdf,
    // VueQrcode 
  },
  data() {
    return {
      certificateName: "",
      dialog: true,
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      is_prakerja: null,
      data: {},
      test: "10",
      member: {},
      program: "",
      competence: [],
      dialogCertif: false,
      styleObject: {
        paddingTop: "100px",
        paddingBottom: "180px",
      },
      styleObject2: {
        paddingTop: "100px",
        paddingBottom: "100px",
      },
      styleObject3: {
        paddingTop: "100px",
        paddingBottom: "80px",
      },
      timer: "",
      score: "",
      dateFinish: [],
      tgl: null,
    };
  },
  computed: {
    // tgl() {
    //   return this.dateFinish[this.dateFinish.length - 1].created_at;
    // }
    terkustomkustom() {
      return [
        "kesekretariatan dan komunikasi untuk menjadi sekretaris",
        "penerapan manajemen kehumasan untuk menjadi humas",
        "menyusun strategi untuk spesialis pemasaran digital",
        "meningkatkan kemampuan penjualan tenaga pemasaran asuransi",
        "calon pengawas k3 pada kapal niaga"
        // "Meningkatkan Kemampuan Penjualan Tenaga Pemasaran Asuransi",
      ];
    },
    useCustomBg() {
      const titles = [
        "komunikasi dan negosiasi untuk tenaga pemasaran",
        "ilmu dasar kepegawaian untuk pegawai personalia",
        "bahan pendukung pertanian untuk petani terampil",
        "manajemen sumber daya manusia untuk pegawai personalia",
        "menganalisa produk asuransi untuk menjadi tenaga pemasaran asuransi",
        ...this.terkustomkustom,
      ];
      return titles.includes((this.data.program_name || "").toLowerCase());
    },
  },
  created() {
    this.getData();
    // this.data = {
    //   program_name: "Bahan Pendukung Pertanian untuk Petani Terampil",
    //   user_name: "Mia Yulia St",
    //   end_test_at: "2023-06-01",
    //   certificate_url: "https://google.co.id",
    //   license: "LPPXXXXXXXX",
    // };
    // this.certificateName = "Mia Yulia St";
    // setTimeout(() => {
    //   this.showCertif();
    // }, 200);
    // this.timer = setInterval(this.getData, 2000)
  },
  mounted() { },
  methods: {
    getData() {
      this.false = true;
      this.axios
        // .get(`users/v1/member_redeem/detail/${this.$route.params.id}`,{headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }})
        .get(`users/v1/public/order/detail/${this.$route.params.id}`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
        })
        .then((response) => {
          let res = response.data.data;
          this.data = res;
          this.certificateName = res.user_name + " - " + res.program_name;
          setTimeout(() => {
            this.showCertif();
          }, 200);
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    currentDateTime(a) {
      return moment(a).lang("id").format("DD MMMM YYYY");
    },
    currentDateTimes(a) {
      return moment(a).lang("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    convertHour(val) {
      let minutes = 0;
      let hours = 0;
      let last = "";
      if (val > 60) {
        hours = val / 60;
        minutes = val % 60;
        if (minutes > 0) {
          last = parseInt(hours) + " Jam " + minutes + " Menit";
        } else {
          last = hours + " Jam ";
        }
      } else {
        minutes = val;
        last = minutes + " Menit";
      }
      return last;
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            console.log("berhasil", res);
          }
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    getDetail(order) {
      this.id = order;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.id}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail = res.program;
          console.log("data detail", this.detail);
          this.showCertif(this.id);
        });
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=PT+Sans:wght@400;700&display=swap");

.style1 {
  padding-top: 100px;
  padding-bottom: 188px
}

table {
  border-collapse: collapse;
}

th {
  background-color: #eee;
}

table,
th,
td {
  border: 1px solid;
}

th,
td {
  padding: 0.5rem;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>
